import { useQuery } from '@tanstack/react-query';
import { CreditsService, unwrapApiResponse } from '@salesport/api';

export function useCreditBalance() {
  const { data: creditBalance } = useQuery({
    queryKey: ['credit-balance'],
    queryFn: async () =>
      unwrapApiResponse(await CreditsService.creditsControllerGetUserCredits()),
    retry: false,
  });

  return creditBalance;
}
