import {
  Avatar as NextUIAvatar,
  AvatarGroup as NextUIAvatarGroup,
  AvatarIcon as NextUIAvatarIcon,
  type AvatarGroupProps as NextUIAvatarGroupProps,
} from '@nextui-org/avatar';

export { NextUIAvatar as Avatar };

type AvatarGroupProps = NextUIAvatarGroupProps;
export const AvatarGroup: React.FC<AvatarGroupProps> = (props) => {
  return <NextUIAvatarGroup {...props} />;
};

export const AvatarIcon = () => {
  return <NextUIAvatarIcon />;
};
