import { createBrowserClient } from '@supabase/ssr';
import type { Database } from '@salesport/db-types';

import { clientEnv } from '@/env/client-env';

// Define a function to create a Supabase client for client-side operations
export const createClient = () =>
  createBrowserClient<Database>(
    // Pass Supabase URL and anonymous key from the environment to the client
    clientEnv.supabaseUrl,
    clientEnv.supabaseAnonKey,
  );
