export const authViews = [
  'signin',
  'signup',
  'forgot-password',
  'update-password',
] as const;
export type AuthView = (typeof authViews)[number];
const routes = {
  root: {
    path: '/',
    build: () => routes.root.path,
  },
  auth: {
    path: '/auth/:view',
    build: (view: AuthView) => routes.auth.path.replace(':view', view),
    callback: {
      path: 'auth/callback',
      build: () => routes.auth.callback.path,
      passwordReset: {
        path: 'auth/callback/password-reset',
        build: () => routes.auth.callback.passwordReset.path,
      },
    },
  },
  account: {
    path: '/account',
    build: () => routes.account.path,
  },
  pricing: {
    path: '/pricing',
    build: () => routes.pricing.path,
  },
} as const;

export default routes;
