import React, { type PropsWithChildren } from 'react';

import { Chip } from '#Chip/index';

export type PrimaryLightChipProps = PropsWithChildren & { size?: 'sm' | 'md' };
export const PrimaryLightChip: React.FC<PrimaryLightChipProps> = ({
  children,
  size = 'md',
}) => (
  <Chip
    size={size}
    classNames={{
      base: 'bg-blue-50',
      content: 'text-primary',
    }}>
    {children}
  </Chip>
);
