'use client';

import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';

import {
  SignInWithOAuthCredentials,
  type Provider,
} from '@supabase/supabase-js';

import { getURL } from '@/common/utils/helpers';
import { createClient } from '@/common/utils/supabase/client';
import routes from '@/routes';
import { redirectToPath } from './server';

export async function handleRequest(
  e: React.FormEvent<HTMLFormElement>,
  requestFunc: (formData: FormData) => Promise<string>,
  router: AppRouterInstance | null = null,
): Promise<boolean | void> {
  // Prevent default form submission refresh
  e.preventDefault();

  const formData = new FormData(e.currentTarget);
  const redirectUrl: string = await requestFunc(formData);

  if (router) {
    // If client-side router is provided, use it to redirect
    return router.push(redirectUrl);
  } else {
    // Otherwise, redirect server-side
    return await redirectToPath(redirectUrl);
  }
}

export async function signInWithOAuth(e: React.FormEvent<HTMLFormElement>) {
  // Prevent default form submission refresh
  e.preventDefault();
  const formData = new FormData(e.currentTarget);
  const provider = String(formData.get('provider')).trim() as Provider;
  let oAuthOptions: SignInWithOAuthCredentials['options'] = {};
  if (provider === 'google') {
    oAuthOptions = {
      queryParams: {
        access_type: 'offline',
        prompt: 'consent',
      },
    };
  } else if (provider === 'azure') {
    oAuthOptions = {
      scopes: 'email offline_access',
    };
  }
  const redirectURL = getURL(routes.auth.callback.build());
  oAuthOptions.redirectTo = redirectURL;

  const supabase = createClient();
  await supabase.auth.signInWithOAuth({
    provider: provider,
    options: oAuthOptions,
  });
}
