import {
  Navbar as NextUINavbar,
  NavbarBrand as NextUINavbarBrand,
  NavbarContent as NextUINavbarContent,
  NavbarItem as NextUINavbarItem,
  NavbarMenu as NextUINavbarMenu,
  NavbarMenuItem as NextUINavbarMenuItem,
  NavbarMenuToggle as NextUINavbarMenuToggle,
  type NavbarBrandProps as NextUINavbarBrandProps,
  type NavbarContentProps as NextUINavbarContentProps,
  type NavbarItemProps as NextUINavbarItemProps,
  type NavbarMenuItemProps as NextUINavbarMenuItemProps,
  type NavbarMenuProps as NextUINavbarMenuProps,
  type NavbarMenuToggleProps as NextUINavbarMenuToggleProps,
  type NavbarProps as NextUINavbarProps,
} from '@nextui-org/navbar';

type NavbarProps = NextUINavbarProps;
export const Navbar: React.FC<NavbarProps> = (props) => {
  return <NextUINavbar {...props} />;
};

type NavbarBrandProps = NextUINavbarBrandProps;
export const NavbarBrand: React.FC<NavbarBrandProps> = (props) => {
  return <NextUINavbarBrand {...props} />;
};

type NavbarContentProps = NextUINavbarContentProps;
export const NavbarContent: React.FC<NavbarContentProps> = (props) => {
  return <NextUINavbarContent {...props} />;
};

type NavbarItemProps = NextUINavbarItemProps;
export const NavbarItem: React.FC<NavbarItemProps> = (props) => {
  return <NextUINavbarItem {...props} />;
};

type NavbarMenuToggleProps = NextUINavbarMenuToggleProps;
export const NavbarMenuToggle: React.FC<NavbarMenuToggleProps> = (props) => {
  return <NextUINavbarMenuToggle {...props} />;
};

type NavbarMenuProps = NextUINavbarMenuProps;
export const NavbarMenu: React.FC<NavbarMenuProps> = (props) => {
  return <NextUINavbarMenu {...props} />;
};

type NavbarMenuItemProps = NextUINavbarMenuItemProps;
export const NavbarMenuItem: React.FC<NavbarMenuItemProps> = (props) => {
  return <NextUINavbarMenuItem {...props} />;
};
