import { forwardRef } from 'react';

import {
  Button as NextUIButton,
  ButtonGroup as NextUIButtonGroup,
  type ButtonGroupProps as NextUIButtonGroupProps,
  type ButtonProps as NextUIButtonProps,
} from '@nextui-org/button';

type ButtonProps = NextUIButtonProps;
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    return <NextUIButton ref={ref} {...props} />;
  },
);

Button.displayName = 'Button';

type ButtonGroupProps = NextUIButtonGroupProps;
export const ButtonGroup: React.FC<ButtonGroupProps> = (props) => {
  return <NextUIButtonGroup {...props} />;
};
