import {
  Dropdown as NextUIDropdown,
  DropdownItem as NextUIDropdownItem,
  DropdownMenu as NextUIDropdownMenu,
  DropdownSection as NextUIDropdownSection,
  DropdownTrigger as NextUIDropdownTrigger,
  type DropdownMenuProps as NextUIDropdownMenuProps,
  type DropdownProps as NextUIDropdownProps,
  type DropdownSectionProps as NextUIDropdownSectionProps,
  type DropdownTriggerProps as NextUIDropdownTriggerProps,
} from '@nextui-org/dropdown';

type DropdownProps = NextUIDropdownProps;
export const Dropdown: React.FC<DropdownProps> = (props) => {
  return <NextUIDropdown {...props} />;
};

type DropdownTriggerProps = NextUIDropdownTriggerProps;
export const DropdownTrigger: React.FC<DropdownTriggerProps> = (props) => {
  return <NextUIDropdownTrigger {...props} />;
};

type DropdownMenuProps = NextUIDropdownMenuProps;
export const DropdownMenu: React.FC<DropdownMenuProps> = (props) => {
  return <NextUIDropdownMenu {...props} />;
};

type DropdownSectionProps = NextUIDropdownSectionProps;
export const DropdownSection: React.FC<DropdownSectionProps> = (props) => {
  return <NextUIDropdownSection {...props} />;
};

export { NextUIDropdownItem as DropdownItem };
