export const SalesPortLogo: React.FC = () => {
  return (
    <svg
      width="122"
      height="17"
      viewBox="0 0 122 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3487_28948)">
        <g clipPath="url(#clip1_3487_28948)">
          <path
            d="M121.979 13.272V15.886C121.635 15.9708 121.151 16.0154 120.531 16.0154C118.213 16.0154 117.056 14.88 117.056 12.6135V2.04041H120.121V4.80388H122V7.18757H120.121V12.2903C120.121 13.0135 120.502 13.3731 121.266 13.3731L121.979 13.268V13.272Z"
            fill="#031130"
          />
          <path
            d="M28.4751 11.6761L31.5197 11.0579C31.5935 11.7811 31.8767 12.367 32.3732 12.8113C32.8697 13.2598 33.5878 13.482 34.5232 13.482C35.3727 13.482 36.0332 13.3325 36.5092 13.0255C36.9852 12.7225 37.2231 12.2942 37.2231 11.7448C37.2231 11.2923 37.0221 10.9287 36.616 10.6459C36.2097 10.363 35.7132 10.1489 35.1264 10.0035C34.5396 9.85801 33.8872 9.69641 33.1733 9.51864C32.4593 9.34087 31.8027 9.11866 31.2078 8.86009C30.6088 8.59749 30.1082 8.15307 29.7061 7.51876C29.304 6.8885 29.1028 6.11279 29.1028 5.19163C29.1028 3.87454 29.6076 2.76753 30.6128 1.87465C31.6222 0.981782 32.9805 0.533325 34.6956 0.533325C36.3081 0.533325 37.613 0.953502 38.6059 1.7979C39.5989 2.64229 40.1816 3.71697 40.354 5.01791L37.3094 5.5714C37.2807 4.89266 37.0303 4.34319 36.5626 3.92302C36.0948 3.50688 35.4588 3.2968 34.6504 3.2968C33.9159 3.2968 33.3252 3.45436 32.8778 3.77354C32.4306 4.0927 32.209 4.49672 32.209 4.99366C32.209 5.40172 32.3609 5.73705 32.6645 5.99159C32.9682 6.24612 33.3579 6.44004 33.8381 6.57337C34.314 6.70669 34.8433 6.85214 35.4178 7.01374C35.9922 7.17535 36.579 7.33696 37.174 7.5026C37.7689 7.66825 38.3105 7.90662 38.7906 8.20963C39.2706 8.51668 39.6646 8.96111 39.9642 9.54289C40.2678 10.1247 40.4155 10.8236 40.4155 11.6357C40.4155 12.9972 39.8575 14.1082 38.7413 14.9647C37.6252 15.8213 36.1605 16.2496 34.3468 16.2496C32.5331 16.2496 31.1053 15.8133 30.1451 14.9406C29.189 14.0678 28.631 12.9811 28.471 11.6761H28.4751Z"
            fill="#031130"
          />
          <path
            d="M58.5025 15.9505H55.4374V0H58.5025V15.9505Z"
            fill="#031130"
          />
          <path
            d="M68.2641 12.3142L71.0953 12.9526C70.6645 13.9747 70.0121 14.7707 69.1422 15.3444C68.2723 15.9181 67.2013 16.2049 65.9335 16.2049C64.2634 16.2049 62.897 15.6595 61.8302 14.5687C60.7634 13.4778 60.2299 12.0718 60.2299 10.3588C60.2299 8.64579 60.7634 7.31658 61.8302 6.21361C62.897 5.10661 64.2552 4.5531 65.9129 4.5531C67.5706 4.5531 68.9164 5.10257 69.9628 6.20149C71.005 7.30041 71.5302 8.68618 71.5302 10.3588L71.4851 11.2517H63.2787C63.3812 12.0153 63.6685 12.601 64.1444 13.0051C64.6204 13.4092 65.2441 13.6112 66.0237 13.6112C67.1192 13.6112 67.866 13.1789 68.2682 12.3142H68.2641ZM65.8883 6.97719C65.199 6.97719 64.6286 7.16709 64.1814 7.54282C63.7342 7.91856 63.4469 8.43165 63.3155 9.08616H68.4569C68.3421 8.44781 68.0589 7.93876 67.6157 7.55495C67.1685 7.17113 66.594 6.98123 65.8883 6.98123V6.97719Z"
            fill="#031130"
          />
          <path
            d="M72.3837 12.6981L75.4282 12.2739C75.457 12.7144 75.6294 13.0739 75.9453 13.3567C76.2612 13.6396 76.7208 13.7809 77.3281 13.7809C77.9354 13.7809 78.3416 13.688 78.6452 13.5062C78.9489 13.3203 79.1007 13.0739 79.1007 12.7628C79.1007 12.4234 78.8832 12.169 78.4524 12.0073C78.0215 11.8457 77.5004 11.7124 76.8972 11.6154C76.2941 11.5184 75.6827 11.3811 75.0712 11.2114C74.4599 11.0417 73.9389 10.7064 73.5038 10.2135C73.073 9.71654 72.8555 9.05799 72.8555 8.2338C72.8555 7.19951 73.274 6.32685 74.1071 5.61981C74.9441 4.91278 76.0355 4.55725 77.3896 4.55725C78.8134 4.55725 79.8966 4.88854 80.6394 5.54709C81.3821 6.20563 81.8293 6.98943 81.9894 7.89847L79.0966 8.43176C79.0678 8.00755 78.9038 7.6601 78.6123 7.3894C78.3169 7.11871 77.9025 6.98538 77.3692 6.98538C76.8808 6.98538 76.4993 7.09043 76.2243 7.29244C75.9494 7.49849 75.814 7.75706 75.814 8.06815C75.814 8.35096 75.9699 8.57317 76.2777 8.73882C76.5854 8.90042 76.9711 9.01355 77.4347 9.07011C77.8944 9.12668 78.3907 9.2196 78.9242 9.34484C79.4577 9.47413 79.9541 9.63574 80.4137 9.8337C80.8733 10.0317 81.259 10.367 81.5708 10.8437C81.8786 11.3205 82.0345 11.9184 82.0345 12.6416C82.0345 13.6759 81.6077 14.5324 80.7502 15.203C79.8926 15.8778 78.7231 16.2132 77.2419 16.2132C74.3204 16.2132 72.6996 15.0415 72.3837 12.7022V12.6981Z"
            fill="#031130"
          />
          <path
            d="M87.143 10.2299V15.9507H83.9877V0.852661H90.5529C92.3091 0.852661 93.6221 1.28496 94.4837 2.14955C95.3496 3.01415 95.7804 4.14943 95.7804 5.55136C95.7804 6.9533 95.3372 8.06839 94.451 8.93299C93.5647 9.79758 92.264 10.2299 90.5529 10.2299H87.139H87.143ZM87.143 7.38156H90.5569C91.2463 7.38156 91.7674 7.21996 92.1121 6.8927C92.4568 6.56545 92.6291 6.12103 92.6291 5.55136C92.6291 4.98171 92.4568 4.53325 92.1121 4.20195C91.7674 3.87066 91.2463 3.70097 90.5569 3.70097H87.143V7.38156Z"
            fill="#031130"
          />
          <path
            d="M106.527 14.516C105.431 15.6432 103.999 16.2089 102.227 16.2089C100.454 16.2089 99.0218 15.6432 97.9263 14.516C96.8308 13.3888 96.285 12.0111 96.285 10.3789C96.285 8.74668 96.8308 7.36898 97.9263 6.24179C99.0218 5.11458 100.454 4.54895 102.227 4.54895C103.999 4.54895 105.431 5.11458 106.527 6.24179C107.622 7.36898 108.168 8.74668 108.168 10.3789C108.168 12.0111 107.622 13.3888 106.527 14.516ZM100.167 8.05986C99.6251 8.62547 99.3584 9.40119 99.3584 10.3789C99.3584 11.3566 99.6291 12.1283 100.167 12.698C100.708 13.2636 101.394 13.5504 102.231 13.5504C103.068 13.5504 103.753 13.2677 104.294 12.698C104.836 12.1323 105.103 11.3566 105.103 10.3789C105.103 9.40119 104.832 8.62952 104.294 8.05986C103.753 7.49423 103.068 7.20738 102.231 7.20738C101.394 7.20738 100.708 7.49019 100.167 8.05986Z"
            fill="#031130"
          />
          <path
            d="M115.952 4.72272V7.55083C115.734 7.50638 115.533 7.48618 115.349 7.48618C114.442 7.48618 113.761 7.72051 113.309 8.18917C112.854 8.65784 112.628 9.39314 112.628 10.3991V15.9503H109.563V4.80756H112.394V6.25393C113.043 5.21966 114.11 4.70251 115.591 4.70251L115.956 4.72272H115.952Z"
            fill="#031130"
          />
          <path
            d="M50.5135 4.7959H53.3365V15.9104H50.5135V14.5529C49.7667 15.6276 48.5727 16.1649 46.9355 16.1649C45.2983 16.1649 44.0756 15.6155 43.1441 14.5125C42.2087 13.4095 41.7449 12.0237 41.7449 10.3552C41.7449 8.68658 42.2127 7.30081 43.1441 6.19784C44.0756 5.09488 45.3434 4.54541 46.9355 4.54541C48.5275 4.54541 49.7627 5.08276 50.5135 6.15744V4.79994V4.7959ZM49.5985 12.6217C50.1074 12.0278 50.3617 11.2723 50.3617 10.3511C50.3617 9.42998 50.1074 8.67446 49.5985 8.08056C49.0897 7.48665 48.4167 7.19172 47.5838 7.19172C46.7509 7.19172 46.0779 7.49069 45.5692 8.08056C45.0604 8.67446 44.8059 9.42998 44.8059 10.3511C44.8059 11.2723 45.0604 12.0278 45.5692 12.6217C46.0779 13.2156 46.7509 13.5105 47.5838 13.5105C48.4167 13.5105 49.0897 13.2156 49.5985 12.6217Z"
            fill="#031130"
          />
          <path
            d="M24.4827 8.18539L17.462 1.27267C16.9819 0.799975 16.3295 0.533325 15.6484 0.533325H8.26664C8.0902 0.533325 7.94659 0.674731 7.94659 0.848457V4.53309C7.94659 4.70681 8.0902 4.84822 8.26664 4.84822H16.391C16.5674 4.84822 16.7111 4.98962 16.7111 5.16336V8.84798C16.7111 9.0217 16.8547 9.16311 17.0312 9.16311H24.0764C24.5934 9.16311 24.8519 8.54901 24.4867 8.18943L24.4827 8.18539Z"
            fill="#185DF1"
          />
          <path
            d="M16.3869 11.9346H8.26253C8.0861 11.9346 7.94248 11.7932 7.94248 11.6195V7.93488C7.94248 7.76115 7.79887 7.61975 7.62244 7.61975H0.581289C0.0642818 7.61975 -0.194222 8.23386 0.170966 8.59343L7.19569 15.5101C7.67577 15.9829 8.32819 16.2496 9.00932 16.2496H16.391C16.5675 16.2496 16.711 16.1081 16.711 15.9343V12.2498C16.711 12.076 16.5675 11.9346 16.391 11.9346H16.3869Z"
            fill="#185DF1"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3487_28948">
          <rect width="122" height="16.9873" fill="white" />
        </clipPath>
        <clipPath id="clip1_3487_28948">
          <rect width="122" height="16.9873" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
